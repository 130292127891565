
import Pic from '@/components/Pic.vue'
import { Address, readAddress } from '@/pages/setting/setting.api'
import { computed, defineComponent, shallowRef } from 'vue'

export default defineComponent({
  name: 'Address',
  components: { Pic },
  props: {},
  setup () {
    const address = shallowRef<Address | null>(null)
    const isEmpty = computed(() => !address.value?.recipientAddress)

    readAddress().then(resp => address.value = resp)

    return {
      isEmpty,
      address,
    }
  },
})
